.checkbox-container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  .checkbox-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: $base-font-size;
    padding-left: 2rem;
    line-height: 1.3;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $grey-text-color;
    font-weight: 400;

    .checkbox-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox-input:checked ~ .checkbox-checkmark:after {
      display: block;
    }

    .checkbox-checkmark {
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-appearance: none;
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      margin: 0;
      top: 0.2rem;
      left: 0;
      border: thin solid $border-color;
      background-color: $white-color;
      transition: all 0.3s ease-in-out;
      outline: none;

      &:after {
        position: absolute;
        display: none;
        height: 1.3rem;
        width: 1.7rem;
        content: "";
        // TODO: kiedy bedziemy miec od graficzki checkboxa podmienic! najlepiej w svg.
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAANCAMAAAB4p3UoAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAJBQTFRFAAAA8Pj4U6KmF4GHh77BSZyhFICGSZidUKClW6arPZab6vT06PT0m8nM0ufoZ62xNJGX5vHyO5WaGYKIvtzedLS3LY2T4O7vHYWLIYeN1Ojpgbu+JIeN2uvsl8fJjcHEtsvMd7W5j8PGHISKx9/nXKerwt7gRpugt9jaM5GWFoGHq9LU3e3tOJOZHoWLpM7QClFGHgAAADB0Uk5TAMD///////////8wcP////+g///////w////////////////IP//////////////tMNpgAAAAHxJREFUeJxVzucWgjAMBeBI9SIiS0BEpoPhQN//7Uhx0OZH0345aUKkxsIQSw1oBQgNTABrFSyGjU1bx/2Cx+AHRDuE0QQxwz7hywFIj5wzhryQpVKWAqo4pfWn/ST/O/Nxuf5GNJgibOepnQTRK3vYN+D+0HZ9Dq/3/zECB6YF2abbIdcAAAAASUVORK5CYII=");
        left: 0.2rem;
        bottom: 0.2rem;
      }

      &:checked {
        color: $secondary-color;
      }
    }

    .checkbox-label {
      a {
        font-size: $base-font-size;
        color: $grey-text-color;
      }
    }
  }
}
