.group-control {
  display: flex;
  border-radius: 2rem;
  overflow: hidden;
  border: 0.1rem solid $input-border-color;

  .filter-field-type {
    margin-right: 0;
    min-width: 11rem;
    width: 11rem;
    padding: 0 1rem;
    border: 0;
    border-right:  0.1rem solid $input-border-color;
    border-radius: 0.6rem 0 0 0.6rem;
    color: $theme-second-color;
    opacity: 0.75;
    background: $control-group-background;
    font-size: $small-font-size;
  }
  .rs-picker-toggle {
    border: 0!important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
