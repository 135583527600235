.select {
  position: relative;

  &:before {
    content: "\e8bd";
    font-family: "feather";
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &.calendar-select, &.aftercare-select {
    &:before {
      display: none;
    }

    width: 30rem;

    .basic-single {
      .select__control {
        border: none;
        height: 3.3rem;
        background: none;
      }
    }
  }

  &.aftercare-select {
    width: 24rem;
  }

  .select-input {
    height: 4rem;
    border: 0.1rem solid $theme-color;
    background: $white-color;
    padding: 0.5rem 1rem 0.5rem 3.4rem;
    color: $theme-color;
    border-radius: 0.5rem;

  }

  .select-list {
    position: absolute;
    top: 3.6rem;
    left: 0;
    right: 0;
    list-style: none;
    border-radius: 0.6rem;
    border: 0.1rem solid $theme-color;
    background-color: #fff;
    z-index: 3;

    .select-list-item {
      padding: .65rem 1.1rem;
      cursor: pointer;

      &.no-data {
        color: $danger-color;
        font-weight: 600;
      }

      &:nth-of-type(odd) {
        background-color: rgba(#e5e5ea, 0.4);
      }

      &:hover {
        background-color: rgba(#e5e5ea, 0.8);

      }
    }
  }
}

// select styles Select z Form z common-web
.basic-single {
  width: 100%;

  .select__control {
    min-height: 4rem;
    height: 3.1rem;
    border: 0.1rem solid $card-border-bottom-color;
    border-radius: 2rem;

    .select__value-container {
      padding: 0 0.8rem;

      .select__single-value {
        color: $input-text-color;
      }
    }

    .select__indicators {
      height: 100%;

      .select__indicator {
        color: $dark-color;
      }
    }

    &.select__control--is-focused {
      color: $input-text-color;
      background-color: $white-color;
      border-color: $input-border-color;
      outline: 0;
      box-shadow: none;
      height: 3.1rem;
    }
  }

  .select__menu {
    margin-top: 0.4rem;
    z-index: 9999;
    border-radius: 2rem;
    overflow: hidden;
  }

  &.currency-select {
    .select__control {
      background-color: #f9f9f9;
      border-left: none;
      -webkit-border-top-left-radius: 0;
      -webkit-border-bottom-left-radius: 0;
      -moz-border-radius-topleft: 0;
      -moz-border-radius-bottomleft: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -2rem;
      padding-left: 2rem;

      .select__value-container {
        justify-content: center;
      }
    }
  }
}


