@import "theme-variables";
.tabs {
  .nav-tabs {
    background-color: $calendar-tabs-background;
    font-weight: 500;

    .nav-item {
      margin-bottom: -.1rem;
      font-size: $base-font-size;
      padding: 0.5rem 0 0 0.5rem;
    }

    .nav-link {
      border: .1rem solid transparent;
      padding: 1rem;
      text-transform: uppercase;
      border-radius: 0;
      color: $grey-text-color;

      &:hover{
        border-color: $theme-border;
        color: lighten($grey-text-color, 20%)
      }

      &.disabled {
        color: rgba($base-text-color, 0.4);
        background-color: transparent;
        border-color: transparent;
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      color: $main-color;
      border-color: $default-color $default-color $white-color;
    }
  }


  .tab-content-container {
    padding: 3.6rem 0 0 0;
  }
}
