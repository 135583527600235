.navbar {
  display: block;
  z-index: 1028;
  padding: 0;
  transition: all 0.3s ease-in-out;
  width: $menu-width;
  background: $white-color;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  color: $secondary-color;

  ul {
    list-style: none;

    &.sidebar-menu {
      margin-top: 2rem;
    }

    li {
      &.nav-item {
        padding: 0.5rem 1rem;
        margin: 0.5rem 0 0;

        &:hover {
          color: transparentize($theme-second-color, 0.9);
          background: transparent;
        }
      }

      .nav-link {
        display: flex;
        align-items: center;
        color: $theme-second-color;
        font-size: 1.6rem;

        .nav-link-text {
          // height: 2rem;
          overflow: hidden;
          text-transform: uppercase;
          font-size: 1.2rem;
          line-height: 2.5;
          letter-spacing: 0.91px;
          font-weight: 800;

          &.collapsed {
            transform: rotateY(-90deg);
            transform-origin: 0 0;
            display: none;
          }
        }

        &.active {
          font-weight: 600;
          cursor: initial;
          color: $theme-second-color;
        }

        &:not(.active):hover {
          font-weight: 400;
          color: $theme-second-color;
          cursor: pointer;
        }
      }
    }
  }

  &.navbar-collapsed {
    width: $menu-collapsed-width;
    transition: all 0.3s ease-in-out;

    .submenu {
      display: none!important;
    }

    .nav-link {
      color: $base-text-color;
      font-size: 1.6rem;

      &:after {
        display: none;
      }
    }
  }
}

.nav-link-text {
  margin-left: 0.5rem;

  &.collapsed {
    transform: rotateY(-90deg);
    transform-origin: 0 0;
    display: none;
    transition: all 0.3s ease-in-out;
  }
}

.has-menu {
  position: relative;
  width: 100%;
  color: #535763;
  text-align: left;
  font-weight: 400;
  margin: 0.5rem 0 0;
  padding: 0.5rem 1rem;
  display: block;
  font-size: 1.6rem;

  &.nav-link-trigger {
    .nav-link {
      &:after {
        transform: rotate(90deg);
      }
    }
  }

  &.side-menu-trigger.active {
    > .submenu {
      display: block;
      padding: 0.5rem 0;
      margin-left: 2.5rem;
    }
  }

  .nav-link {
    &:after {
      content: "\e844";
      font-family: 'feather';
      position: absolute;
      top: 1.75rem;
      right: 2rem;
      transition: all 0.3s ease-in-out;
    }
  }

  .submenu {
    display: none;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      font-family: unset;
      position: absolute;
      top: 0.5rem;
      left: 0;
      bottom: 1.8rem;
      width: 0.1rem;
      background: transparentize($secondary-color, .8);
    }

    li {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 1.75rem;
        left: 0.1rem;
        width: 1.5rem;
        height: .1rem;
        background: transparentize($secondary-color, .8);
      }

      > a {
        text-align: left;
        padding: 0 1.75rem;
        margin: 0.5rem 0 0 0;
        display: block;
        font-weight: 400;
        font-size: $base-font-size;

        &:before, &:after {
          display: none;
        }
      }
    }
  }
}

.nav-link-icon {
  font-size: 1.6rem;
  min-width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media only screen and (max-width: 991px) {
  .navbar {
    margin-left: -#{$menu-width};
    position: absolute;
    height: 100%;
    box-shadow: none;

    &.mob-open {
      margin-left: 0;
    }
  }
}
