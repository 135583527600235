@import "~meditrip-styles/panel/theme-variables";

.commission-info {
  display: flex;
  div:first-child {
    width: 5rem;
    margin-right: 0.5rem;
  }

  div:last-child {
    font-weight: 600;
    flex: 1;
  }
}

.clinic-select {
  &:before {
    display: none;
  }
  width: 30rem;
  .basic-single {
    .select__control {
      border: none;
      height: 3.8rem;
      background: none;
    }
    .select__menu {
      z-index: 10000;
    }
  }
}

.form-label {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: 400;
  margin-bottom: .3rem;
  color: $grey-text-color;
  font-size: 1.4rem;
  min-height: 1.7rem;
}

label[for=reminderAmount] {
  width: 22rem;
}

form {
  .custom-input-group {
    flex-direction: row-reverse;
    margin-top: 2.3rem;
    align-items: center;
    justify-content: flex-end;

    label {
      width: auto;
      margin-left: 1.5rem;
    }

    .basic-single {
      width: 50%;
    }
  }

  .form-row {
    .input-group {
      textarea {
        resize: vertical;
      }
    }
  }

  .clinic-widget-row {
    position: absolute;
    left: 50%;
    bottom: 18rem;

    .input-group {
      margin-bottom: 0!important;

      label {
        text-align: initial;
        margin: 0;
      }

      .checkbox-container {
        .checkbox-wrapper {
          padding: 0.2rem 0 0 2rem;
        }
      }

      &.col-100 {
        padding: 0 0.5rem;
      }

      &:first-child {
        padding-bottom: 0.5rem;
      }
    }
  }

  label[for=reviews],
  label[for=aftercare],
  label[for=onlineConsultation],
  label[for=isPlanActive] {
    font-weight: bold;
    font-size: $m-font-size;
    color: $view-title-color;
  }
}

.checkbox-container {
  width: 100%;

  .checkbox-wrapper {
    width: 100%;
    background-color: transparent;
    padding: 0.2rem 2rem;

    .checkbox-checkmark {
      top: auto;
    }
  }
}

label[for=isPlanActive],
label[for=reviews],
label[for=aftercare],
label[for=onlineConsultation] {
  width: auto;
  margin-right: 1rem;
  color: $view-title-color;
}

label[for=eventType] + .radio-control {
  display: flex;

  .radio-wrapper:first-child {
    margin-right: 3rem;
    .radio-label {
      background-color: $success-color;
      border-radius: 2rem;
    }
  }

  .radio-wrapper {
    .radio-label {
      text-transform: uppercase;
      background-color: $primary-color;
      color: $white-color;
      padding: 0.3rem 0.5rem;
    }
  }
}

#rteTools {
  .rte-control-section {
    .e-richtexteditor.e-rte-tb-expand {
      border-color: #ccc;
    }
      margin: 0 auto;
  }

  .e-html-preview::before {
    content: '\e350';
  }

  .e-richtexteditor {
    .e-rte-content {
      .e-content {
        float: left;
        width: 100%;
      }
    }
  }

  .content-wrapper {
    width: auto;
    margin: 0;
  }
}
