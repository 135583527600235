table.data-table {
  // border-collapse: collapse;
  width: 100%;
  &.specialist-details {
    tbody {
      tr {
        td {
          text-align: left;
        }
      }
    }
  }
  &.no-hover {
    tbody {
      tr {
        &:hover {
          &:nth-child(2n) {
            background: $table-row-background-color;
          }
          &:nth-child(2n + 1) {
            background: initial;
          }
        }
      }
    }
  }

  thead {
    tr {
      height: 5.5rem;
      background-color: transparent;
      align-items: center;
      th {
        padding: 1rem;
        min-width: 8rem;
        font-weight: 600;
        font-size: $base-font-size;
        color: $view-title-color;
        vertical-align: middle;
        color: $white-color;
        text-align: center;
        text-transform: uppercase;
        line-height: 1.3;
        background-color: $table-head-background-color;

        &:first-child {
          border-top-left-radius: $border-radius-small;
          border-bottom-left-radius: $border-radius-small;
        }
        &:last-child {
          border-bottom-right-radius: $border-radius-small;
          border-top-right-radius: $border-radius-small;
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n),
      &:hover {
        background: transparent;
        td {
          background: $table-row-background-color;
          &:first-child {
            border-top-left-radius: $border-radius-small;
            border-bottom-left-radius: $border-radius-small;
          }
          &:last-child {
            border-bottom-right-radius: $border-radius-small;
            border-top-right-radius: $border-radius-small;
          }
        }
      }

      td {
        vertical-align: middle;
        padding: 1rem;
        color: $grey-text-color;
        text-align: center;
        a {
          &:hover {
            text-decoration: none;
            font-weight: 500;
            color: $theme-color;
          }
        }
      }
    }
  }
}
