// toDO PPP , move form-control styles here
// quick fix for current form style mess

.basic-multi-select {
  width: 100%;

  .select__control {
    border-radius: 2rem;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .checkbox-input {
    margin-right: 1rem;
  }
}

.consultation-hours {
  align-items: stretch;
  .consultation-options-input-group {
    justify-content: space-between;
  }
}

.country-select-value {
  display: flex;
  align-items: center;

  img {
    height: 2.1rem;
    width: 2.8rem;
    margin-right: 1rem;
  }
}
