.auth-form-container {
  .auth-form {
    width: 100%;
  }

  .button-container {
    padding: 0;

    .btn {
      height: 4.9rem;
      width: 100%;
    }
  }

  .checkbox-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    .checkbox-wrapper {
      display: inline-flex;
      align-items: center;
      position: relative;
      font-size: $base-font-size;
      padding: 0.2rem 0 0 2rem;
      line-height: 1.3;
      cursor: pointer;
      width: initial;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: 400;
      background-color: transparent;
      margin: 0;

      .checkbox-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkbox-input:checked ~ .checkbox-checkmark:after {
        display: block;
      }

      .checkbox-checkmark {
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-appearance: none;
        position: absolute;
        height: 1.5rem;
        width: 1.5rem;
        margin: 0;
        top: 0.4rem;
        left: 0;
        border: thin solid $border-color;
        background-color: $white-color;
        transition: all 0.3s ease-in-out;
        outline: none;

        &:after {
          content: "";
          color: $danger-color;
          position: absolute;
          display: none;
          padding: 0.4rem;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }

        &:checked {
          color: $secondary-color;
        }
      }

      .checkbox-label {
        width: 100%;
        display: inline;
        font-size: $base-m-font-size;
        text-align: left;
        padding: 0;
        background-color: transparent;
        color: inherit;

        a {
          color: $grey-text-color;
        }
      }
    }

    &.checkbox-hidden {
      .checkbox-checkmark {
        display: none;
      }
    }
  }
}
