.custom-card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: $border-radius-small;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.15);
  position: relative;

  .custom-card-header {
    padding: 3rem 3rem 2rem;

    .custom-card-title {
      border-bottom: none; //0.1rem solid #ced4da;
      margin: 0;
      font-weight: bold;
      color: $view-title-color;
      font-size: $m-font-size;
    }

    & + .custom-card-body {
      padding-top: 0;
    }
  }

  .custom-card-body {
    padding: 2rem 4.4rem 2rem;
    flex: 1 1 auto;
    min-height: 0.1rem;
  }

  .custom-card-footer {
    padding: 3rem;
    flex: 1 1 auto;
    min-height: 0.1rem;

    .modal-footer-confirmation {
      font-size: 1.6rem;
    }
  }

  &:not(.modal-card) {
    margin-bottom: 2rem;
  }

  &.modal-card {
    padding: 2rem 2rem;

    .custom-card-body {
      padding: 0;

      .modal-body {
        padding: 0;
      }

      .modal-footer {
        border-top: none;
      }
    }
  }
}
