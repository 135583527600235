.form-control { // toDo fix structure, form-control-container > .form-control ... ?
  position: relative;
  display: flex;
  width: initial;
  height: 4rem;
  border-radius: 2rem;
  border: thin solid $input-border-color;
  background: $white-color;
  color: $theme-second-color;
  font-size: $base-font-size;
  padding: 0;
  overflow: hidden;

  .input, .select {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0;
    padding: 0 1rem;
    background: transparent;
  }

  &.input-icon {
    padding-left: 3.6rem;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 3.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "feather";
    }

    &.icon-search {
      &:before {
        content: "\e8bd";
      }
    }
  }
}
