.status-wrapper {
  height: 1.8rem;
  padding: 0.5rem 1.2rem;
  font-size: $small-font-size;
  line-height: 1;
  border-radius: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  color: $white-color;

  .status {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }

  &.active,
  &.attached,
  &.paid,
  &.valid,
  &.consultation-succeeded,
  &.travel-plan {
    background-color: $travel-planning-color;
  }

  &.travel-plan-planner {
    height: 2.4rem;
  }

  &.inactive,
  &.invalid,
  &.unpaid,
  &.consultation-scheduled {
    background-color: $warning-color;
  }

  &.expired,
  &.unattached,
  &.deactivated,
  &.consultation-not-started {
    background-color: $error-block-color;
  }

  &.draft,
  &.consultation-cancelled {
    background-color: $danger-color;
  }

  &.invited,
  &.consultation-draft {
    background-color: $secondary-color;
  }

  &.aftercare {
    background-color: $aftercare-color;
  }

  &.consultation-started {
    background-color: $info-color;
  }
}
